import Icon from 'antd/lib/icon';
import Input from 'antd/lib/input';
import cx from 'classnames';
import * as React from 'react';

import { ReactComponent as RightChevronIcon } from 'assets/icons/chevron-right.svg';
import Button from 'components/Button';
import { HStack } from 'components/layout';
import SelectInput from 'components/SelectInput';
import { tc, tNamespaced } from 'utils/i18nUtil';
import { wrapIcon } from 'utils/IconUtils';

import { DefaultPageSizeOptions } from './constants';

import './Pagination.less';

const tn = tNamespaced('AgTable.Pagination');

const LeftChevronIcon: typeof RightChevronIcon = ({ style, ...props }) => (
  <RightChevronIcon
    style={{
      ...style,
      transform: 'rotateZ(180deg)',
    }}
    {...props}
  />
);

interface PagerProps {
  hasPrevious?: boolean;
  hasNext?: boolean;
  onRequestPreviousPage: (evt: React.MouseEvent<HTMLButtonElement>) => void;
  onRequestNextPage: (evt: React.MouseEvent<HTMLButtonElement>) => void;
  pageSize?: number;
  onPageSizeChange?: (newPageSize: number) => void;
  onGotoPageChange?: (newPageNumber: number) => void;
  pageSizeOptions?: number[];
  allowPageSizeChange?: boolean;
  totalRecords?: number;
  allowGotoPage?: boolean;
  pageInfo?: CursorPageInfo;
  simple?: boolean;
  isLoading?: boolean;
}

const Pager = ({
  hasPrevious = false,
  hasNext = false,
  onRequestNextPage,
  onRequestPreviousPage,
  pageSize = DefaultPageSizeOptions[0], // TODO: bump this to 25 (DefaultPageSizeOptions[1])
  onPageSizeChange,
  allowPageSizeChange,
  allowGotoPage,
  onGotoPageChange,
  simple,
  pageInfo = defaultCursorPageInfo,
  pageSizeOptions = DefaultPageSizeOptions,
  totalRecords,
  isLoading,
}: PagerProps) => {
  if (allowPageSizeChange && !onPageSizeChange) {
    throw new Error('onPageSizeChange is required if allowPageSizeChange is set.');
  }
  const [currentPage, setCurrentPage] = React.useState(1);
  const totalPage = Math.ceil(pageInfo?.totalCount ? pageInfo.totalCount / pageSize : 0);

  React.useEffect(() => {
    if (pageInfo?.start) {
      setCurrentPage(Math.ceil((parseInt(pageInfo.start) + 1) / pageSize));
    }
  }, [pageInfo?.start, pageSize]);

  const onGotoPageChangeHandler = React.useCallback(
    (evt: React.ChangeEvent<HTMLInputElement>) => {
      const page = parseInt(evt.target.value);
      if (page <= 0 || isNaN(page)) {
        setCurrentPage(1);
        return;
      } else if (page > totalPage) {
        setCurrentPage(totalPage);
        return;
      }
      setCurrentPage(page);
    },
    [totalPage]
  );

  const buttonType = simple ? 'link' : 'default';

  return (
    <div
      className={cx(
        'syncari-ag-table-pagination-container',
        simple ? 'syncari-ag-table-pagination-container--simple' : null
      )}>
      <HStack spacing={simple ? 'z' : 'md'}>
        {totalRecords && <div className="total-records">{tc('total_records', { totalRecords })}</div>}
        <Button type={buttonType} onClick={onRequestPreviousPage} disabled={!hasPrevious || isLoading}>
          <Icon component={wrapIcon(LeftChevronIcon)} />
        </Button>
        {allowPageSizeChange && onPageSizeChange ? (
          <SelectInput
            disabled={isLoading}
            value={pageSize.toString()}
            options={pageSizeOptions.map((pageSizeOption) => ({
              title: tn('records_per_page', { count: pageSizeOption }),
              label: tn('records_per_page', { count: pageSizeOption }),
              value: pageSizeOption.toString(),
            }))}
            onChange={(value) => {
              onPageSizeChange(+value);
            }}
          />
        ) : null}
        {allowGotoPage && (
          <span>
            <Input
              size="small"
              disabled={isLoading}
              className="syncari-ag-table-pagination-container__goto-page"
              value={currentPage}
              onChange={onGotoPageChangeHandler}
              style={{ width: 40, marginRight: 6 }}
              onKeyDown={(evt: React.KeyboardEvent<HTMLInputElement>) =>
                evt.key === 'Enter' && onGotoPageChange?.(currentPage)
              }
              onBlur={() => onGotoPageChange?.(currentPage)}
            />
            / {totalPage}
          </span>
        )}
        <Button type={buttonType} onClick={onRequestNextPage} disabled={!hasNext || isLoading}>
          <Icon component={wrapIcon(RightChevronIcon)} />
        </Button>
      </HStack>
    </div>
  );
};

export type PaginationDirection = 'next' | 'previous' | 'goTo';

export interface CursorPageInfo {
  start: string | null;
  end: string | null;
  hasMore: boolean;
  hasPrevious?: boolean;
  totalCount?: number;
}

export interface PaginationProps
  extends Omit<PagerProps, 'onRequestNextPage' | 'onRequestPreviousPage' | 'hasNext' | 'hasPrevious'> {
  pageInfo?: CursorPageInfo;
  allowGotoPage?: boolean;
  onRequestNextPage?: (cursor: string, count?: number) => void;
  onRequestPreviousPage?: (cursor: string, count?: number) => void;
  onGotoPageChange?: (newPage: number) => void;
  isLoading?: boolean;
}

const defaultCursorPageInfo = {
  start: null,
  end: null,
  hasMore: false,
  hasPrevious: false,
};

const CursorBasedPagination = ({
  pageInfo = defaultCursorPageInfo,
  onRequestNextPage,
  onRequestPreviousPage,
  onGotoPageChange,
  pageSize,
  allowGotoPage,
  ...props
}: PaginationProps) => {
  const { start, end, hasMore, hasPrevious } = pageInfo;

  const handleRequestPreviousPage = () => {
    start && onRequestPreviousPage?.(start, pageSize);
  };

  const handleRequestNextPage = () => {
    end && onRequestNextPage?.(end, pageSize);
  };

  return (
    <Pager
      hasPrevious={Boolean(start && hasPrevious)}
      pageInfo={pageInfo}
      hasNext={Boolean(end && hasMore)}
      onRequestPreviousPage={handleRequestPreviousPage}
      onRequestNextPage={handleRequestNextPage}
      onGotoPageChange={onGotoPageChange}
      pageSize={pageSize}
      allowGotoPage={allowGotoPage}
      {...props}
    />
  );
};

export interface PageBasedPageInfo {
  pageNumber: number;
  maxPageNumber: number;
}

export interface PageBasedPaginationProps
  extends Omit<PagerProps, 'onRequestNextPage' | 'onRequestPreviousPage' | 'hasNext' | 'hasPrevious' | 'pageInfo'> {
  pageInfo?: PageBasedPageInfo;
  onRequestPreviousPage: (pageNumber: number, count?: number) => void;
  onRequestNextPage: (pageNumber: number, count?: number) => void;
}

/* this gives us an empty Pager instead of a missing pager */
const defaultPageBasedPageInfo = {
  pageNumber: 0,
  maxPageNumber: 0,
};

const PageBasedPagination = ({
  pageInfo = defaultPageBasedPageInfo,
  onRequestPreviousPage,
  onRequestNextPage,
  pageSize,
  ...props
}: PageBasedPaginationProps) => {
  const hasNext = pageInfo.pageNumber < pageInfo.maxPageNumber;
  const hasPrevious = pageInfo.pageNumber > 0;

  const handleRequestNextPage = () => {
    hasNext && onRequestNextPage(pageInfo.pageNumber + 1, pageSize);
  };

  const handleRequestPreviousPage = () => {
    hasPrevious && onRequestPreviousPage(pageInfo.pageNumber - 1, pageSize);
  };

  return (
    <Pager
      hasNext={hasNext}
      hasPrevious={hasPrevious}
      onRequestPreviousPage={handleRequestPreviousPage}
      onRequestNextPage={handleRequestNextPage}
      pageSize={pageSize}
      {...props}
    />
  );
};

export default Pager;
export { CursorBasedPagination, PageBasedPagination };
