export enum MergeOptionValues {
  AFTER_SOURCE = 'AFTER_SOURCE',
  BEFORE_CORE = 'BEFORE_CORE',
  AFTER_CORE = 'AFTER_CORE',
  BEFORE_SINK = 'BEFORE_SINK',
  COPY = 'COPY',
  REPLACE = 'REPLACE',
  MERGE = 'MERGE',
  USE_EXISTING = 'USE_EXISTING',
}

export enum MergeOptionValuesSimple {
  REPLACE = 'REPLACE',
  MERGE = 'MERGE',
}
